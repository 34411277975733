import swal from 'sweetalert2';
import Utilities from '../../Utilities';
import { handleSelectpickerWithAll } from '../../utilities/selectpicker';

const businessID = Utilities.getUrlParameter('business');
const campaignID = Utilities.getUrlParameter('campaign_id');
const projectID = Utilities.getUrlParameter('project');
const productSelectionLimitPlatforms = ['amazon_sales', 'shopify_sales', 'woocommerce_sales', 'amazon_and_shopify_sales', 'amazon_and_woocommerce_sales', 'shopify_and_woocommerce_sales', 'amazon_shopify_and_woocommerce_sales'];

let $selectProductsModalAmazonCustomSelection;
let $selectProductsModalAmazonCustomSelectionHolder;
let $selectProductsModalAmazonLimitToSpecificCountries;
let $selectProductsModalAmazonSpecificCountriesHolder;
let $selectProductsModalAmazonSelectAll;
let $selectProductsModalShopifyCustomSelection;
let $selectProductsModalShopifyCustomSelectionHolder;
let $selectProductsModalShopifyLimitToSpecificCountries;
let $selectProductsModalShopifySpecificCountriesHolder;
let $selectProductsModalShopifySelectAll;
let $selectProductsModalWooCommerceCustomSelection;
let $selectProductsModalWooCommerceCustomSelectionHolder;
let $selectProductsModalWooCommerceLimitToSpecificCountries;
let $selectProductsModalWooCommerceSpecificCountriesHolder;
let $selectProductsModalWooCommerceSelectAll;
let $amazonTabSpinner;
let $shopifyTabSpinner;
let $wcTabSpinner;

/**
 *
 * FUNCTIONS
 *
 */

/**
 * Update Goal Events.
 */
function updateGoalEvents() {
  const isPrimary = ($('.goallink').length === 0 || $('#goal_id').val() === $('.goallink:first').data('goal'));
  let options;
  let rollUpOptions;
  let optionsHTML = '<option value="">Select One</option>';
  let rollUpOptionsHTML = '<option value="">Select One</option>';
  const goalType = $('#goal_type').val();

  if (goalType.toString() === '2') {
    // ROAS
    options = (isPrimary) ? window.primaryRoasGoalEvents : window.roasGoalEvents;
    rollUpOptions = window.rollUpRoasGoalEvents;
  } else {
    // CP or Total
    options = (isPrimary) ? window.primaryOtherGoalEvents : window.otherGoalEvents;
    rollUpOptions = window.rollUpOtherGoalEvents;
  }

  // Convert to HTML
  optionsHTML += options.reduce((accumulator, currentValue) => `${accumulator}<option value="${currentValue.column}">${currentValue.name}</option>`, '');

  // Convert to HTML
  rollUpOptionsHTML += rollUpOptions.reduce((accumulator, currentValue) => `${accumulator}<option value="${currentValue.column}">${currentValue.name}</option>`, '');

  $('#goal_conv_roll_up').html(`${rollUpOptionsHTML}<option value="no_roll_up">Don't Roll Up</option>`);

  optionsHTML += '<option data-divider="true" disabled></option><option value="amazon_sales">Amazon Sales</option>';
  optionsHTML += '<option value="shopify_sales">Shopify Sales</option>';
  optionsHTML += '<option value="woocommerce_sales">WooCommerce Sales</option>';
  optionsHTML += '<option value="amazon_and_shopify_sales">Amazon + Shopify Sales</option>';
  optionsHTML += '<option value="amazon_and_woocommerce_sales">Amazon + WooCommerce Sales</option>';
  optionsHTML += '<option value="shopify_and_woocommerce_sales">Shopify + WooCommerce Sales</option>';
  optionsHTML += '<option value="amazon_shopify_and_woocommerce_sales">Amazon + Shopify + WooCommerce Sales</option>';
  optionsHTML += '<option data-divider="true" disabled></option><option value="custom_conversion">Custom Conversion</option>';

  $('#goal_conv').html(optionsHTML);
}

function populateProductSelections(goalConv) {
  $('#cgSelectProductsDiv').show();

  let pullAmazon = false;
  let pullShopify = false;
  let pullWooCommerce = false;

  $('#cgAmazonTab').hide();
  $('#cgShopifyTab').hide();
  $('#cgWoocommerceTab').hide();

  switch (goalConv) {
    case 'amazon_sales':
      pullAmazon = true;
      $('#cgAmazonTab a').click();
      break;
    case 'shopify_sales':
      pullShopify = true;
      $('#cgShopifyTab a').click();
      break;
    case 'woocommerce_sales':
      pullWooCommerce = true;
      $('#cgWoocommerceTab a').click();
      break;
    case 'amazon_and_shopify_sales':
      pullAmazon = true;
      pullShopify = true;
      $('#cgAmazonTab a').click();
      break;
    case 'amazon_and_woocommerce_sales':
      pullAmazon = true;
      pullWooCommerce = true;
      $('#cgAmazonTab a').click();
      break;
    case 'shopify_and_woocommerce_sales':
      pullShopify = true;
      pullWooCommerce = true;
      $('#cgShopifyTab a').click();
      break;
    case 'amazon_shopify_and_woocommerce_sales':
      pullAmazon = true;
      pullShopify = true;
      pullWooCommerce = true;
      $('#cgAmazonTab a').click();
      break;
  }

  if (pullAmazon) {
    $('#cgAmazonTab').show();
    $amazonTabSpinner.show();

    $.getJSON(`/campaign/edit/goal/amazon-products/get?project_id=${projectID}&campaign=${campaignID}`, (amazonData) => {
      let selectedProductCheckboxesHTML = '';

      if (amazonData.success) {
        let customSelection = false;

        $.each(Object.values(amazonData.products).sort(), (i, item) => {
          if (item !== null) {
            const checkedProp = (item.selected) ? ' checked' : '';

            // If we have at least one selected product then that means we have a custom selection
            if (item.selected) {
              customSelection = true;
            }

            selectedProductCheckboxesHTML += `
                <div class="row align-items-center m-b-0_5">
                    <div class="col-3">
                        <img src="${item.default_image}" alt="" style="max-width: 75px" />
                    </div>
                    <div class="col">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" name="cg_amazon_product[${item.asin}]" id="cg_amazon_product_${item.asin}" value="${item.name.replace('"', '\\"')}" ${checkedProp}>
                            <label class="custom-control-label" for="cg_amazon_product_${item.asin}">${item.name}</label>
                        </div>
                    </div>
                </div>`;
          }
        });

        $selectProductsModalAmazonCustomSelection.prop('checked', customSelection).trigger('change');
      } else {
        selectedProductCheckboxesHTML += amazonData.message;
      }

      $('#cgSelectAmazonProducts-checkboxes').html(selectedProductCheckboxesHTML);
      $amazonTabSpinner.hide();
    });
  }

  if (pullShopify) {
    $('#cgShopifyTab').show();
    $shopifyTabSpinner.show();

    $.getJSON(`/campaign/edit/goal/shopify-products/get?project_id=${projectID}&campaign=${campaignID}`, (shopifyData) => {
      let selectedProductCheckboxesHTML = '';

      if (shopifyData.success) {
        let customSelection = false;

        $.each(Object.values(shopifyData.products).sort(), (i, item) => {
          if (item !== null) {
            const checkedProp = (item.selected) ? ' checked' : '';

            // If we have at least one selected product then that means we have a custom selection
            if (item.selected) {
              customSelection = true;
            }

            selectedProductCheckboxesHTML += `
                <div class="row align-items-center m-b-0_5">
                    <div class="col-3">
                        <img src="${item.default_image}" alt="" style="max-width: 75px" />
                    </div>
                    <div class="col">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" name="cg_shopify_product[${item.id}]" id="cg_shopify_product_${item.id}" value="${item.name.replace('"', '\\"')}" ${checkedProp}>
                            <label class="custom-control-label" for="cg_shopify_product_${item.id}">${item.name}</label>
                        </div>
                    </div>
                </div>`;
          }
        });

        $selectProductsModalShopifyCustomSelection.prop('checked', customSelection).trigger('change');
      } else {
        selectedProductCheckboxesHTML += shopifyData.message;
      }

      $('#cgSelectShopifyProducts-checkboxes').html(selectedProductCheckboxesHTML);
      $shopifyTabSpinner.hide();
    });
  }

  if (pullWooCommerce) {
    $('#cgWoocommerceTab').show();
    $wcTabSpinner.show();

    $.getJSON(`/campaign/edit/goal/woocommerce-products/get?project_id=${projectID}&campaign=${campaignID}`, (woocommerceData) => {
      let selectedProductCheckboxesHTML = '';

      if (woocommerceData.success) {
        let customSelection = false;

        $.each(Object.values(woocommerceData.products).sort(), (i, item) => {
          if (item !== null) {
            const checkedProp = (item.selected) ? ' checked' : '';

            // If we have at least one selected product then that means we have a custom selection
            if (item.selected) {
              customSelection = true;
            }

            selectedProductCheckboxesHTML += `
                <div class="row align-items-center m-b-0_5">
                    <div class="col-3">
                        <img src="${item.default_image}" alt="" style="max-width: 75px" />
                    </div>
                    <div class="col">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" name="cg_woocommerce_product[${item.id}]" id="cg_woocommerce_product_${item.id}" value="${item.name.replace('"', '\\"')}" ${checkedProp}>
                            <label class="custom-control-label" for="cg_woocommerce_product_${item.id}">${item.name}</label>
                        </div>
                    </div>
                </div>`;
          }
        });

        $selectProductsModalWooCommerceCustomSelection.prop('checked', customSelection).trigger('change');
      } else {
        selectedProductCheckboxesHTML += woocommerceData.message;
      }

      $('#cgSelectWooCommerceProducts-checkboxes').html(selectedProductCheckboxesHTML);

      $wcTabSpinner.hide();
    });
  }
}

export const init = () => {
  $(() => {
    $selectProductsModalAmazonCustomSelection = $('#cgSelectAmazonProducts-custom-selection');
    $selectProductsModalAmazonCustomSelectionHolder = $('#cgSelectAmazonProducts-custom-selection-holder');
    $selectProductsModalAmazonLimitToSpecificCountries = $('#cgSelectAmazonProducts-limit-to-specific-countries');
    $selectProductsModalAmazonSpecificCountriesHolder = $('#cgSelectAmazonProducts-specific-countries').closest('.form-group');
    $selectProductsModalAmazonSelectAll = $('#cgSelectAmazonProducts-select-all');
    $selectProductsModalShopifyCustomSelection = $('#cgSelectShopifyProducts-custom-selection');
    $selectProductsModalShopifyCustomSelectionHolder = $('#cgSelectShopifyProducts-custom-selection-holder');
    $selectProductsModalShopifyLimitToSpecificCountries = $('#cgSelectShopifyProducts-limit-to-specific-countries');
    $selectProductsModalShopifySpecificCountriesHolder = $('#cgSelectShopifyProducts-specific-countries').closest('.form-group');
    $selectProductsModalShopifySelectAll = $('#cgSelectShopifyProducts-select-all');
    $selectProductsModalWooCommerceCustomSelection = $('#cgSelectWooCommerceProducts-custom-selection');
    $selectProductsModalWooCommerceCustomSelectionHolder = $('#cgSelectWooCommerceProducts-custom-selection-holder');
    $selectProductsModalWooCommerceLimitToSpecificCountries = $('#cgSelectWooCommerceProducts-limit-to-specific-countries');
    $selectProductsModalWooCommerceSpecificCountriesHolder = $('#cgSelectWooCommerceProducts-specific-countries').closest('.form-group');
    $selectProductsModalWooCommerceSelectAll = $('#cgSelectWooCommerceProducts-select-all');
    $amazonTabSpinner = $('#cg-amazon-tab .product-spinner');
    $shopifyTabSpinner = $('#cg-shopify-tab .product-spinner');
    $wcTabSpinner = $('#cg-woocommerce-tab .product-spinner');

    $('.goals.sortable').sortable({ placeholderClass: 'list-group-item clearfix creativelink' }).bind('sortupdate', () => {
      const sortOrder = [];

      $('.sortable.goals a').each(function processSortable() {
        sortOrder.push($(this).data('goal'));
      });

      const sortOrderString = sortOrder.join(',');

      $.ajax({
        url: `/campaign/edit/process?project_id=${projectID}&campaign=${campaignID}`, type: 'get', data: { sortgoals: sortOrderString }, dataType: 'json',
      })
        .done((data) => {
          if (!data.success) {
            $('#goal-error-main-body').html(data.message);
            $('#goal-error-main-alert').fadeTo(2000, 500).slideDown(100, () => {
              $('#goal-error-main-alert').slideUp(500);
            });
          }
        })
        .fail((jqXHR, status, error) => {
          $('#goal-error-main-body').html(error);
          $('#goal-error-main-alert').fadeTo(2000, 500).slideDown(100, () => {
            $('#goal-error-main-alert').slideUp(500);
          });
        });
    });

    $('#goal_conv').on('change', (event) => {
      if ($(event.currentTarget).val() === 'custom_conversion') {
        $('#goal_conv_cc_wrapper').show();
        $('#goal_conv_roll_up_wrapper').show();
      } else {
        $('#goal_conv_cc_wrapper').hide();
        $('#goal_conv_cc').val('');
        $('#goal_conv_roll_up_wrapper').hide();
        $('#goal_conv_roll_up').val('');

        if (productSelectionLimitPlatforms.includes($('#goal_conv').val())) {
          populateProductSelections($('#goal_conv').val());

          // Display a warning if the goal conv chosen uses an ecomm platform and there isn't a corresponding active account connector
          const unconnectedPlatforms = [];
          if ((window.hasAmazonStoreConnected === '0') && ['amazon_sales', 'amazon_and_shopify_sales', 'amazon_and_woocommerce_sales', 'amazon_shopify_and_woocommerce_sales'].includes($('#goal_conv').val())) {
            unconnectedPlatforms.push('Amazon');
          }
          if ((window.hasShopifyStoreConnected === '0') && ['shopify_sales', 'amazon_and_shopify_sales', 'shopify_and_woocommerce_sales', 'amazon_shopify_and_woocommerce_sales'].includes($('#goal_conv').val())) {
            unconnectedPlatforms.push('Shopify');
          }
          if ((window.hasShopifyStoreConnected === '0') && ['woocommerce_sales', 'amazon_and_woocommerce_sales', 'shopify_and_woocommerce_sales', 'amazon_shopify_and_woocommerce_sales'].includes($('#goal_conv').val())) {
            unconnectedPlatforms.push('WooCommerce');
          }

          if (unconnectedPlatforms.length) {
            swal.fire({
              title: 'Missing Account Connector!',
              text: 'You are missing active connections for the following connector(s) for the event you selected. Connector(s) missing: ' + unconnectedPlatforms.join(', '),
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-secondary',
              },
              confirmButtonText: 'Okay, I will go connect it!',
            });
          }
        }
      }
    });

    $('#goal_type').on('change', () => {
      updateGoalEvents();
    });

    $('#addnew-goal').on('click', (event) => {
      event.preventDefault();
      $('#cgSelectProductsDiv').hide();
      $('#goal-error-main-alert, #goal-success-alert, #goal_delete_container').hide();
      $('#goal-form').trigger('reset');
      updateGoalEvents();
      $('#goal-new').val('1');
      $('#goal_id').val('new');
      $('#goal-delete').hide();
      $('#goal-cancel').html('Cancel');
      $('#goal-submit').html('Add Goal');
      $('#goal_conv_roll_up_wrapper').hide();
      $('#goalModal').appendTo('body').modal('show');
    });

    $('[id^=goallink]').on('click', (event) => {
      $('.loader, #loading-overlay').show();
      $('#cgSelectProductsDiv').hide();
      event.preventDefault();
      $('#goal-error-main-alert, #goal-success-alert, #goal_delete_container').hide();
      $('#goal-form').trigger('reset');
      $('#goal_delete_container').show();
      $('#goal-new').val('0');
      $('#goal-delete').show();
      $('#goal-cancel').html('Cancel');
      $('#goal-submit').html('Update Goal');
      const goalId = $(event.currentTarget).data('goal');
      $('#goal_id').val(goalId);

      $.ajax({ url: `/campaign/edit/goal/get/process?project_id=${projectID}&campaign=${campaignID}&goal=${goalId}`, dataType: 'json' })
        .done((data) => {
          if (data.campaign_goal_id) {
            $('#goal_name').val(data.name);
            $('#goal_type').val(data.goal_id).trigger('change');
            $('#goal_value').val(data.goal_value);
            $('#goal_conv').val(data.goal_conv).trigger('change');
            $('#goal_conv_cc').val(data.goal_conv_cc);
            $('#goal_conv_roll_up').val(data.goal_conv_roll_up);
            $('#goal_adset').val(data.adset_id);
            $('#goal_status').val(data.active);
            $('#goalModal').appendTo('body').modal('show');

            let goal_countries_amazon = data.goal_countries_amazon || '';
            let goal_countries_shopify = data.goal_countries_shopify || '';
            let goal_countries_wc = data.goal_countries_wc || '';

            if (productSelectionLimitPlatforms.includes(data.goal_conv)) {
              $('#cgSelectShopifyProducts-exclude-recurring-and-prepaid').prop('checked', data.shopify_goal_exclude_recurring_and_prepaid === '1').trigger('change');
              $('#cgSelectWooCommerceProducts-exclude-recurring-and-prepaid').prop('checked', data.wc_goal_exclude_recurring_and_prepaid === '1').trigger('change');
              $selectProductsModalAmazonLimitToSpecificCountries.prop('checked', !!goal_countries_amazon.length).trigger('change');
              $selectProductsModalShopifyLimitToSpecificCountries.prop('checked', !!goal_countries_shopify.length).trigger('change');
              $selectProductsModalWooCommerceLimitToSpecificCountries.prop('checked', !!goal_countries_wc.length).trigger('change');

              $('#cgSelectAmazonProducts-specific-countries').val(goal_countries_amazon.split(',')).selectpicker('refresh');
              $('#cgSelectShopifyProducts-specific-countries').val(goal_countries_shopify.split(',')).selectpicker('refresh');
              $('#cgSelectWooCommerceProducts-specific-countries').val(goal_countries_wc.split(',')).selectpicker('refresh');
            }


            $('.loader, #loading-overlay').hide();
          } else {
            $('#goal-error-main-body').html(data.message);
            $('#goal-error-main-alert').slideDown(100);
          }
        })
        .fail((jqXHR, status, error) => {
          $('#goal-error-main-body').html(error);
          $('#goal-error-main-alert').slideDown(100);
        });
    });

    $('#goal-delete').on('click', () => {
      $('#goal-delete').addClass('btn-waiting').prop('disabled', true);
      swal.fire({
        title: 'Are you sure?',
        text: 'This will remove this goal from the list of available goals for this campaign and campaign scoring.',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.value) {
          const goalId = $('#goal_id').val();
          $.ajax({
            url: `/campaign/edit/goal/process?project_id=${projectID}&campaign=${campaignID}&goal=${goalId}&action=delete`, type: 'get', data: $('#goal-form').serialize(), dataType: 'json',
          })
            .done((data) => {
              if (data.success) {
                // window.location.reload();
                swal.fire({
                  title: 'Deleted!',
                  text: 'The goal has been successfully deleted.',
                  icon: 'success',
                  // showCancelButton: true,
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-secondary',
                  },
                  // confirmButtonText: 'Yes, delete it!',
                  onClose: () => {
                    window.location.reload();
                  },
                });
              } else {
                swal.fire({
                  title: 'Uh oh!',
                  text: data.message,
                  icon: 'error',
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-secondary',
                  },
                });
                $('#goal-delete').removeClass('btn-waiting').prop('disabled', false);
              }
            })
            .fail((jqXHR, status, error) => {
              $('#goal-delete').removeClass('btn-waiting').prop('disabled', false);
              $('#goal-error-body').html(error);
              $('#goal-error-alert').slideDown(100);
            });
        } else {
          $('#goal-delete').removeClass('btn-waiting').prop('disabled', false);
        }
      });
    });

    $('#goal-submit').on('click', (event) => {
      event.preventDefault();
      $('#goal-error-alert, #goal-success-alert').hide();
      $('#goal-submit').addClass('btn-waiting').prop('disabled', true);
      const goalId = $('#goal_id').val();
      $.ajax({
        url: `/campaign/edit/goal/process?project_id=${projectID}&campaign=${campaignID}&goal=${goalId}`, type: 'get', data: $('#goal-form').serialize(), dataType: 'json',
      })
        .done((data) => {
          if (data.success) {
            if (parseInt($('#goal-new').val(), 10) === 1) {
              window.location.reload();
            } else {
              $('#goal-submit').removeClass('btn-waiting').prop('disabled', false);
              $('#goal-success-alert').slideDown(100);
              $(`#goal-name-${goalId}`).html(data.name);
              $(`#goal-type-${goalId}`).html(data.type);
              $(`#goal-value-${goalId}`).html(data.value);
              $(`#goal-event-${goalId}`).html(data.conversion_name);
              $(`#goal-event-custom-${goalId}`).html(data.conversion_value);
              $(`#goal-date-${goalId}`).html(data.date);
              $('#goal-cancel').html('Close');
            }
          } else {
            $('#goal-submit').removeClass('btn-waiting').prop('disabled', false);
            $('#goal-error-body').html(data.message);
            $('#goal-error-alert').slideDown(100);
          }
        })
        .fail((jqXHR, status, error) => {
          $('#goal-submit').removeClass('btn-waiting').prop('disabled', false);
          $('#goal-error-body').html(error);
          $('#goal-error-alert').slideDown(100);
        });
    });

    $('.selectpicker-with-all').on('change', (e) => {
      handleSelectpickerWithAll(e.currentTarget);
    }).trigger('change');

    $selectProductsModalAmazonCustomSelection.on('change', function selectAmazonProductsModalCustomSelectionChange() {
      if ($(this).is(':checked')) {
        $selectProductsModalAmazonCustomSelectionHolder.show();
      } else {
        $selectProductsModalAmazonCustomSelectionHolder.hide();
      }
    }).trigger('change');

    $selectProductsModalAmazonLimitToSpecificCountries.on('change', function selectProductsModalAmazonLimitToSpecificCountriesChange() {
      if ($(this).is(':checked')) {
        $selectProductsModalAmazonSpecificCountriesHolder.show();
      } else {
        $selectProductsModalAmazonSpecificCountriesHolder.hide();
      }
    }).trigger('change');

    $selectProductsModalAmazonSelectAll.on('change', function selectAmazonProductsModalSelectAllChange() {
      $('#cgSelectAmazonProducts-checkboxes input[type="checkbox"]').prop('checked', $(this).is(':checked'));
    });

    $('#cgSelectAmazonProducts-checkboxes input[type="checkbox"]').on('change', () => {
      $selectProductsModalAmazonSelectAll.prop('checked', false);
    });

    $selectProductsModalShopifyCustomSelection.on('change', function selectProductsModalShopifyCustomSelectionChange() {
      if ($(this).is(':checked')) {
        $selectProductsModalShopifyCustomSelectionHolder.show();
      } else {
        $selectProductsModalShopifyCustomSelectionHolder.hide();
      }
    }).trigger('change');

    $selectProductsModalShopifyLimitToSpecificCountries.on('change', function selectProductsModalShopifyLimitToSpecificCountriesChange() {
      if ($(this).is(':checked')) {
        $selectProductsModalShopifySpecificCountriesHolder.show();
      } else {
        $selectProductsModalShopifySpecificCountriesHolder.hide();
      }
    }).trigger('change');

    $selectProductsModalShopifySelectAll.on('change', function selectProductsModalShopifySelectAllChange() {
      $('#cgSelectShopifyProducts-checkboxes input[type="checkbox"]').prop('checked', $(this).is(':checked'));
    });

    $('#cgSelectShopifyProducts-checkboxes input[type="checkbox"]').on('change', () => {
      $selectProductsModalShopifySelectAll.prop('checked', false);
    });

    $selectProductsModalWooCommerceCustomSelection.on('change', function selectProductsModalWooCommerceCustomSelectionChange() {
      if ($(this).is(':checked')) {
        $selectProductsModalWooCommerceCustomSelectionHolder.show();
      } else {
        $selectProductsModalWooCommerceCustomSelectionHolder.hide();
      }
    }).trigger('change');

    $selectProductsModalWooCommerceLimitToSpecificCountries.on('change', function selectProductsModalWooCommerceLimitToSpecificCountriesChange() {
      if ($(this).is(':checked')) {
        $selectProductsModalWooCommerceSpecificCountriesHolder.show();
      } else {
        $selectProductsModalWooCommerceSpecificCountriesHolder.hide();
      }
    }).trigger('change');

    $selectProductsModalWooCommerceSelectAll.on('change', function selectProductsModalWooCommerceSelectAllChange() {
      $('#cgSelectWooCommerceProducts-checkboxes input[type="checkbox"]').prop('checked', $(this).is(':checked'));
    });

    $('#cgSelectWooCommerceProducts-checkboxes input[type="checkbox"]').on('change', () => {
      $selectProductsModalWooCommerceSelectAll.prop('checked', false);
    });

    /** EVENT TRIGGER HANDLERS -- END */
  });
};

export default { init };
