import moment from 'moment';
import swal from 'sweetalert2';
import Utilities from '../../Utilities';
import { handleSelectpickerWithAll } from '../../utilities/selectpicker';

const campaignID = Utilities.getUrlParameter('campaign_id');
const projectID = Utilities.getUrlParameter('project');

let $autoOptFormBuilds;
let $autoOptFormBuildsButton;
let $autoOptimize;
let $autoTestEnd;
let $budgetScalingBasedOn;
let $budgetScalingEvent;
let $budgetScalingGoalType;
let $buildEndDates;
let $buildStartDates;
let $buildUtilizeForOverruns;
let $conversionSourcePercentageFacebook;
let $conversionSourcePercentageGoogle;
let $conversionSourcePercentageSlider;
let $pendingBudgetChangesModal;
let $pendingBudgetChangesModalForm;
let $pendingBudgetChangesModalOverrideDailyBudgetEnd;
let $pendingBudgetChangesModalApproveButton;
let $pendingBudgetChangesModalDenyButton;
let $aoScalingBudget;
let $aoScalingBudgetEvent;
let $aoScalingBudgetEventCC;
let $aoScalingBudgetGoalTypeCPP;
let $aoScalingBudgetGoalTypeROAS;
let $selectProducts;
let $selectProductsModalAmazonCustomSelection;
let $selectProductsModalAmazonCustomSelectionHolder;
let $selectProductsModalAmazonLimitToSpecificCountries;
let $selectProductsModalAmazonSpecificCountriesHolder;
let $selectProductsModalAmazonSelectAll;
let $selectProductsModal;
let $selectProductsModalForm;
let $selectProductsModalShopifyCustomSelection;
let $selectProductsModalShopifyCustomSelectionHolder;
let $selectProductsModalShopifyLimitToSpecificCountries;
let $selectProductsModalShopifySpecificCountriesHolder;
let $selectProductsModalShopifySelectAll;
let $selectProductsModalWooCommerceCustomSelection;
let $selectProductsModalWooCommerceCustomSelectionHolder;
let $selectProductsModalWooCommerceLimitToSpecificCountries;
let $selectProductsModalWooCommerceSpecificCountriesHolder;
let $selectProductsModalWooCommerceSelectAll;
let $selectProductsModalSubmitButton;

/**
 *
 * FUNCTIONS
 *
 */

/**
 * Initialize the autoTest end date picker.
 */
function endDateAutoTest() {
  $autoTestEnd.daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
  },
  (start) => {
    const dateSelected = start.format('MM/DD/YYYY');
    $autoTestEnd.val(dateSelected);
  });
}

/**
 * Validate Run Dates.
 */
function validateRunDates() {
  const $invalidBudgetAlertOverlappingRunDates = $('.budgetAlerts #invalidBudgetAlert-overlappingRunDates');
  let runDatesValid = true;

  // Clear all invalids
  $buildEndDates.removeClass('is-invalid');
  $buildStartDates.removeClass('is-invalid');

  // Get all dates
  const allDates = {
    dpa: [],
    normal: [],
  };

  $buildStartDates.each(function processBuildStartDates() {
    const id = $(this).attr('id');
    const idParts = id.split('_');
    const buildID = idParts[1];
    const startID = `buildStartDates_${buildID}`;
    const endID = `buildEndDates_${buildID}`;
    const end = $(`#${endID}`).val();
    const start = $(`#${startID}`).val();
    const dateType = $(this).attr('data-type');

    allDates[dateType].push({
      end, endID, start, startID,
    });
  });

  // Now check for overlap
  Object.entries(allDates).forEach(([dateType, datesForType]) => {
    datesForType.forEach((item) => {
      allDates[dateType].forEach((otherItem) => {
        if (item.startID !== otherItem.startID) {
          // const itemStartsBeforeOther = moment(item.start).isBetween(otherItem.start, otherItem.end, null, '[]');
          // const otherStartsBeforeItem = moment(item.start).isBetween(otherItem.start, otherItem.end, null, '[]');

          const startOverlaps = moment(item.start).isBetween(otherItem.start, otherItem.end, null, '[]');
          const endOverlaps = moment(item.end).isBetween(otherItem.start, otherItem.end, null, '[]');

          // Start date falls between another build's range
          if (startOverlaps) {
            runDatesValid = false;
            $(`#${item.startID}`).addClass('is-invalid');
            $(`#${otherItem.endID}`).addClass('is-invalid');
          }

          // End date falls between another build's range
          if (endOverlaps) {
            runDatesValid = false;
            $(`#${item.endID}`).addClass('is-invalid');
            $(`#${otherItem.startID}`).addClass('is-invalid');
          }

          // Same start dates
          if (item.start.length > 0 && item.start === otherItem.start) {
            runDatesValid = false;
            $(`#${item.startID}`).addClass('is-invalid');
            $(`#${otherItem.startID}`).addClass('is-invalid');
          }

          // Same end dates
          if (item.end.length > 0 && item.end === otherItem.end) {
            runDatesValid = false;
            $(`#${item.endID}`).addClass('is-invalid');
            $(`#${otherItem.endID}`).addClass('is-invalid');
          }

          // 2+ items that run forever
          if (item.end.length === 0 && otherItem.end.length === 0) {
            runDatesValid = false;
            $(`#${item.endID}`).addClass('is-invalid');
            $(`#${otherItem.endID}`).addClass('is-invalid');
          }
        }
      });
    });
  });

  // Check Run Dates
  if (!runDatesValid) {
    $invalidBudgetAlertOverlappingRunDates.show();
  } else {
    $invalidBudgetAlertOverlappingRunDates.hide();
  }

  $autoOptFormBuildsButton.prop('disabled', (!runDatesValid || $autoOptFormBuilds.is('[disabled]')));
}

/**
 * Initialize the build start date picker.
 *
 * @param buildID
 * @param date
 * @param maxDate
 * @param checkForOverlaps
 */
function startDateBuilds(buildID, date = false, maxDate = false, checkForOverlaps = true) {
  $(`#buildStartDates_${buildID}`).daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    startDate: date.length ? date : false,
    maxDate: maxDate.length ? maxDate : false,
  },
  (start) => {
    const dateSelected = start.format('MM/DD/YYYY');
    $(`#buildStartDates_${buildID}`).val(dateSelected);
    // eslint-disable-next-line no-use-before-define
    endDateBuilds(buildID, $(`#buildEndDates_${buildID}`).val(), dateSelected, false, false);

    if (checkForOverlaps) {
      validateRunDates();
    }
  });
  if (!date) { $(`#buildStartDates_${buildID}`).val(''); }
}

/**
 * Initialize the build end date picker.
 *
 * @param buildID
 * @param date
 * @param minDate
 * @param checkForOverlaps
 */
function endDateBuilds(buildID, date = false, minDate = false, checkForOverlaps = true) {
  $(`#buildEndDates_${buildID}`).daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    startDate: date.length ? date : false,
    minDate: minDate.length ? minDate : false,
  },
  (start) => {
    const dateSelected = start.format('MM/DD/YYYY');
    $(`#buildEndDates_${buildID}`).val(dateSelected);
    startDateBuilds(buildID, $(`#buildStartDates_${buildID}`).val(), dateSelected, false, false);

    if (checkForOverlaps) {
      validateRunDates();
    }
  });
  if (!date) { $(`#buildEndDates_${buildID}`).val(''); }
}

/**
 * Validate overruns are valid.
 */
function validateOverruns() {
  const $invalidBudgetAlertMultipleOverruns = $('.budgetAlerts #invalidBudgetAlert-multipleOverruns');
  const $invalidBudgetAlertOverrunWithActiveBuilds = $('.budgetAlerts #invalidBudgetAlert-overrunWithActiveBuilds');
  let overrunUtilizationIsValid = true;
  const buildsWithOverrunEnabled = {
    dpa: [],
    normal: [],
  };
  const otherActiveBuilds = {
    dpa: [],
    normal: [],
  };

  // How to check these
  // Count all builds that have an empty end date or an end date that is in the future
  // If the build is the one with overrun enabled then its ok
  // We do not want overrun enabled on a build if there is currently a running build since that would mean we have two builds taking the budget

  $('.utilizeForOverrun input:checked').each(function processOverrunBuilds() {
    const id = $(this).attr('id');
    const idParts = id.split('_');
    const buildID = idParts[1];
    const dateType = $(this).attr('data-type');

    buildsWithOverrunEnabled[dateType].push(buildID);
  });

  $buildEndDates.each(function processBuildEndDates() {
    const id = $(this).attr('id');
    const idParts = id.split('_');
    const buildID = idParts[1];
    const end = $(`#buildEndDates_${buildID}`).val();
    const dateIsInTheFuture = (end.length === 0) ? true : moment().isBefore(end);
    const dateType = $(this).attr('data-type');

    // Make sure that it wasn't counted in the overrun count
    if (dateIsInTheFuture && buildsWithOverrunEnabled[dateType].indexOf(buildID) === -1) {
      otherActiveBuilds[dateType].push(buildID);
    }
  });

  const numDPAOverruns = buildsWithOverrunEnabled.dpa.length;
  const numNormalOverruns = buildsWithOverrunEnabled.normal.length;
  const numOtherActiveDPABuilds = otherActiveBuilds.dpa.length;
  const numOtherActiveNormalBuilds = otherActiveBuilds.normal.length;

  // Check overruns
  if (numDPAOverruns > 1 || numNormalOverruns > 1) {
    $invalidBudgetAlertMultipleOverruns.show();
    overrunUtilizationIsValid = false;
  } else {
    $invalidBudgetAlertMultipleOverruns.hide();
  }

  if ((numDPAOverruns >= 1 && numOtherActiveDPABuilds >= 1) || (numNormalOverruns >= 1 && numOtherActiveNormalBuilds >= 1)) {
    $invalidBudgetAlertOverrunWithActiveBuilds.show();
    overrunUtilizationIsValid = false;
  } else {
    $invalidBudgetAlertOverrunWithActiveBuilds.hide();
  }

  // Disable the submit button on errors
  $autoOptFormBuildsButton.prop('disabled', (!overrunUtilizationIsValid || $autoOptFormBuilds.is('[disabled]')));
}

/**
 * Set auto optimize dropdown background color.
 *
 * @param optimizationSelection
 */
function setAutoOptimizeBackground(optimizationSelection) {
  const $autoOptimizeDropdownToggle = $('select#autoOptimize').closest('.dropdown').find('button.btn');
  $autoOptimizeDropdownToggle.removeClass('bg-success bg-warning');

  if (optimizationSelection === 'view-through') {
    $autoOptimizeDropdownToggle.addClass('bg-warning');
  } else if (optimizationSelection === 'full-auto') {
    $autoOptimizeDropdownToggle.addClass('bg-success');
  }
}

/**
 * Initialize the build date pickers.
 */
function initBuildDatePickers() {
  $buildStartDates.each(function processBuildStartDates() {
    const id = $(this).attr('id');
    const idParts = id.split('_');
    const buildID = idParts[1];
    const buildStart = $(`#buildStartDates_${buildID}`).val();
    const buildEnd = $(`#buildEndDates_${buildID}`).val();

    // Init the datepickers
    startDateBuilds(buildID, buildStart, buildEnd, false);
    endDateBuilds(buildID, buildEnd, buildStart, false);
  });
  validateRunDates();
  validateOverruns();
}

/**
 * Trigger error swal alert.
 *
 * @param errorMessage
 * @returns {Promise<SweetAlertResult<Awaited<unknown>>>}
 */
function triggerErrorSwal(errorMessage) {
  return swal.fire({
    icon: 'error',
    title: errorMessage,
    timer: 3000,
    customClass: {
      confirmButton: 'btn btn-primary',
    },
  });
}

/**
 * Process an AO settings form.
 *
 * @param form
 * @param formName
 * @param $submitButton
 * @param {string} action
 * @param {boolean} reloadWindow
 * @param {string} additionalNoticesTitle
 * @param getAdditionalNoticesFunction
 * @param {string} additionalURLParams
 */
function processAOForm(form, formName, $submitButton, action, reloadWindow = false, additionalNoticesTitle = '', getAdditionalNoticesFunction = null, additionalURLParams = '') {
  $('.loader, #loading-overlay').show();
  $submitButton.prop('disabled', true);

  $.ajax({
    url: `/campaign/edit/budget/process?project_id=${projectID}&campaign=${campaignID}&action=${action}${additionalURLParams}`,
    type: 'post',
    data: $(form).serialize(),
    dataType: 'json',
  })
    .done((data) => {
      $('.loader, #loading-overlay').hide();

      if (data.success) {
        swal.fire({
          icon: 'success',
          title: `${formName} Updated Successfully`,
          timer: 3000,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(() => {
          $submitButton.prop('disabled', false);

          const additionalNotices = (getAdditionalNoticesFunction == null) ? '' : getAdditionalNoticesFunction(data);

          if (additionalNotices.length > 0) {
            swal.fire({
              icon: 'warning',
              title: additionalNoticesTitle,
              html: additionalNotices,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            }).then(() => {
              if (reloadWindow) {
                $('.loader, #loading-overlay').show();
                window.location.reload();
              }
            });
          } else if (reloadWindow) {
            $('.loader, #loading-overlay').show();
            window.location.reload();
          }
        });
      } else {
        triggerErrorSwal(data.message).then(() => {
          $submitButton.prop('disabled', false);
        });
      }
    })
    .fail((jqXHR, status, error) => {
      triggerErrorSwal(error).then(() => {
        $submitButton.prop('disabled', false);
      });
    });
}

/**
 * Set up an AO settings form with validation handling.
 *
 * @param $form
 * @param formName
 * @param $submitButton
 * @param {string} action
 * @param {boolean} reloadWindow
 * @param {string} additionalNoticesTitle
 * @param getAdditionalNoticesFunction
 * @param {string} additionalURLParams
 */
function setupAOForm($form, formName, $submitButton, action, reloadWindow = false, additionalNoticesTitle = '', getAdditionalNoticesFunction = null, additionalURLParams = '') {
  $form.validate({
    invalidHandler() {
      $form.addClass('was-validated');
    },
    submitHandler(form) {
      processAOForm(form, formName, $submitButton, action, reloadWindow, additionalNoticesTitle, getAdditionalNoticesFunction, additionalURLParams);
    },
  });
}

/**
 * Handle toggling on AO Section items.
 * @param $toggle
 * @param $items
 */
function setupAOSectionHandler($toggle, $items) {
  $toggle.on('change', () => {
    $items.prop('readonly', !$toggle.is(':checked') || $toggle.is('[disabled]'));
    $items.attr('readonly', !$toggle.is(':checked') || $toggle.is('[disabled]'));
  }).trigger('change');
}

/**
 *
 * INITS
 *
 */

function initAutoOptimize() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  $autoOptimize.selectpicker({ iconBase: 'fa', tickIcon: 'fa-check' });
  setupAOForm($('#autoOptimizationForm-aoEnabled'), 'Auto Optimize Enabled Status', $('#autoOptimizationForm-aoEnabled-submit'), 'update-ao-settings-auto-optimize-enabled-status', true);
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  $autoOptimize.on('change', (e) => {
    const aoSettingNames = {
      off: 'Off',
      'view-through': 'View Through',
      'full-auto': 'Full Auto',
    };
    const currentAOSetting = window.prevBudgetingData.autoOptimize;
    const currentAOSettingName = aoSettingNames[currentAOSetting];
    const newAOSetting = $autoOptimize.val();
    const newAOSettingName = aoSettingNames[newAOSetting];

    // Confirm Change
    swal.fire({
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'Update Auto Optimize',
      text: `It appears you are changing the Auto Optimize setting from ${currentAOSettingName} to ${newAOSettingName}. Are you sure you want to do this?`,
      reverseButtons: true,
      showCancelButton: true,
      title: 'Confirm Auto Optimize Setting',
      type: 'warning',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary',
      },
    }).then((result) => {
      if (result.value) {
        $('.loader, #loading-overlay').show();
        $autoOptimize.prop('readonly', true);

        $.ajax({
          url: `/campaign/edit/budget/process?project_id=${projectID}&campaign=${campaignID}&action=update-ao-settings-auto-optimize-enabled-status`,
          type: 'post',
          data: $('#autoOptimizationForm-aoEnabled').serialize(),
          dataType: 'json',
        })
          .done((data) => {
            $('.loader, #loading-overlay').hide();

            if (data.success) {
              setAutoOptimizeBackground(e.currentTarget.value);
              window.prevBudgetingData.autoOptimize = newAOSetting;

              swal.fire({
                icon: 'success',
                title: 'Auto Optimize Updated Successfully',
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => {
                $autoOptimize.prop('readonly', false);
              });
            } else {
              triggerErrorSwal(data.message).then(() => {
                $autoOptimize.prop('readonly', false);
                $autoOptimize.val(currentAOSetting).selectpicker('refresh');
              });
            }
          })
          .fail((jqXHR, status, error) => {
            triggerErrorSwal(error).then(() => {
              $autoOptimize.prop('readonly', false);
              $autoOptimize.val(currentAOSetting).selectpicker('refresh');
            });
          });
      } else {
        // Change back to previous value
        $autoOptimize.val(currentAOSetting).selectpicker('refresh');
      }
    });
  });
  setAutoOptimizeBackground($autoOptimize.val());
  /** EVENT TRIGGER HANDLERS -- END */
}

function initTimePeriods() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  setupAOForm($('#autoOptimizationForm-timePeriods'), 'Time Periods', $('#autoOptimizationForm-timePeriods-submit'), 'update-ao-settings-time-periods');
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  /** EVENT TRIGGER HANDLERS -- END */
}

function getBudgetingScalingAdditionalNotices() {
  let warningsToDisplay = '';

  if ($aoScalingBudget.is(':checked') && window.prevBudgetingData.scalingBudget === '0') {
    warningsToDisplay += '<p><strong>You enabled Scaling Budget - </strong> It is recommended to only use this feature when the Ad Account or Store (Amazon, Shopify, or WooCommerce) have been active at least 7 days. Your build(s) will now be budgeted based on the specified goals and account activity. The Campaign Bank will be ignored during optimization. Please ensure you select a build in the table below that you want to use with this feature.</p>';
  }

  return warningsToDisplay;
}

function initScalingBudget() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  $('#autoOptimizationForm-scalingBudget').validate({
    invalidHandler() {
      $(this).addClass('was-validated');
    },
    submitHandler(form) {
      const formName = 'Scaling';
      const $submitButton = $('#autoOptimizationForm-scalingBudget-submit');
      const action = 'update-ao-settings-scaling';
      const reloadWindow = true;
      const additionalNoticesTitle = 'Your Changes Will Impact Auto Optimization';

      // Check if our budget or build end dates are in the past
      let scalingBuildEndDateIsInPast = false;

      $buildUtilizeForOverruns.filter(':checked').each(function processBuildEndDates() {
        const id = $(this).attr('id');
        const idParts = id.split('_');
        const buildID = idParts[1];
        const startID = `buildStartDates_${buildID}`;
        const endID = `buildEndDates_${buildID}`;
        const end = $(`#${endID}`).val();
        const start = $(`#${startID}`).val();
        const endDateIsInThePast = (end.length === 0) ? false : moment(end).isBefore();
        const startDateIsInThePast = (start.length === 0) ? false : moment(start).isBefore();

        if (startDateIsInThePast && endDateIsInThePast) {
          scalingBuildEndDateIsInPast = true;
        }
      });

      const lifetimeBudgetEndDateIsInPast = (moment($('#lifetimeBudgetEnd').val()).isBefore());
      const lifetimeUninvoicedBudgetEndDate = $('#uninvoicedBudgetEnd').val();
      const lifetimeUninvoicedBudgetEndDateIsInPast = (lifetimeUninvoicedBudgetEndDate.length === 0) ? false : (moment(lifetimeUninvoicedBudgetEndDate).isBefore());

      // If Scaling is enabled in AO and the current Scaling Allowed is YES then if we are turning this off we need to ask about shutting it off in the ad account
      if (window.prevBudgetingData.scalingBudget === '1' && window.prevBudgetingData.scalingBudgetAllowed === '1' && !$aoScalingBudget.is(':checked') && (scalingBuildEndDateIsInPast || lifetimeBudgetEndDateIsInPast || lifetimeUninvoicedBudgetEndDateIsInPast)) {
        swal.fire({
          title: 'Turn Off Build Now?',
          html: `<p>You are making a budget change which will result in the build shutting off. Do you want to shut off the build now?</p>
            <p>If you do not, then it will shut off automatically in the morning.</p>
            <p>Please be aware that shutting off the build may take some time. Do not close the window until the process completes, and you receive a confirmation message.</p>`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-secondary',
          },
          cancelButtonText: 'No, turn it off tomorrow',
          confirmButtonText: 'Yes, turn off the build',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            // Simply process the request
            processAOForm(form, formName, $submitButton, action, reloadWindow, additionalNoticesTitle, getBudgetingScalingAdditionalNotices);
          } else {
            // Simply process the request
            processAOForm(form, formName, $submitButton, action, reloadWindow, additionalNoticesTitle, getBudgetingScalingAdditionalNotices, '&nextDayShutOff=1');
          }
        });
      } else {
        // Simply process the request
        processAOForm(form, formName, $submitButton, action, reloadWindow, additionalNoticesTitle, getBudgetingScalingAdditionalNotices);
      }
    },
  });
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  $budgetScalingEvent.on('change', (e) => {
    if (e.currentTarget.value === 'custom_conversion') {
      $aoScalingBudgetEventCC.show();
    } else {
      $aoScalingBudgetEventCC.hide();
    }
  }).trigger('change');

  $budgetScalingGoalType.on('change', (e) => {
    if (e.currentTarget.value === 'roas') {
      $aoScalingBudgetGoalTypeCPP.hide();
      $aoScalingBudgetGoalTypeROAS.show();

      $budgetScalingEvent.find('optgroup[label="CPP Events"]').attr('disabled', true);
      $budgetScalingEvent.find('optgroup[label="ROAS Events"]').prop('disabled', false);
    } else {
      $aoScalingBudgetGoalTypeROAS.hide();
      $aoScalingBudgetGoalTypeCPP.show();

      $budgetScalingEvent.find('optgroup[label="ROAS Events"]').attr('disabled', true);
      $budgetScalingEvent.find('optgroup[label="CPP Events"]').prop('disabled', false);
    }
  }).trigger('change');

  $aoScalingBudget.on('change', () => {
    $('#autoOptimizationForm-scalingBudget .card input:not(.disabled, .btn), #autoOptimizationForm-scalingBudget .card button:not(.disabled, .btn)').prop('readonly', !$aoScalingBudget.is(':checked') || $aoScalingBudget.is('[disabled]'));
    $('#autoOptimizationForm-scalingBudget .card select:not(.disabled)').attr('readonly', !$aoScalingBudget.is(':checked') || $aoScalingBudget.is('[disabled]'));
    $budgetScalingBasedOn.trigger('change');
    $budgetScalingEvent.trigger('change');
    $budgetScalingGoalType.trigger('change');
  }).trigger('change');
  /** EVENT TRIGGER HANDLERS -- END */
}

function initScalingBudgetProducts() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  setupAOForm($selectProductsModalForm, 'Products for Scaling', $selectProductsModalSubmitButton, 'update-ao-settings-products-for-scaling');
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  $selectProducts.on('click', (event) => {
    event.preventDefault();
    $('.loader, #loading-overlay').show();
    const errors = '';

    $.when(
      $.getJSON(`/campaign/edit/budget/amazon-products-for-scaling/get?project_id=${projectID}&campaign=${campaignID}`, (amazonData) => {
        let selectedProductCheckboxesHTML = '';

        if (amazonData.success) {
          let customSelection = false;

          $.each(Object.values(amazonData.products).sort(), (i, item) => {
            if (item !== null) {
              const checkedProp = (item.selected) ? ' checked' : '';

              // If we have at least one selected product then that means we have a custom selection
              if (item.selected) {
                customSelection = true;
              }

              selectedProductCheckboxesHTML += `
                <div class="row align-items-center m-b-0_5">
                    <div class="col-3">
                        <img src="${item.default_image}" alt="" style="max-width: 75px" />
                    </div>
                    <div class="col">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" name="amazon_product[${item.asin}]" id="amazon_product_${item.asin}" value="${item.name.replace('"', '\\"')}" ${checkedProp}>
                            <label class="custom-control-label" for="amazon_product_${item.asin}">${item.name}</label>
                        </div>
                    </div>
                </div>`;
            }
          });

          $selectProductsModalAmazonCustomSelection.prop('checked', customSelection).trigger('change');
        } else {
          selectedProductCheckboxesHTML += amazonData.message;
        }

        $('#selectProductsModal .modal-body #selectAmazonProducts-checkboxes').html(selectedProductCheckboxesHTML);
      }),
      $.getJSON(`/campaign/edit/budget/shopify-products-for-scaling/get?project_id=${projectID}&campaign=${campaignID}`, (shopifyData) => {
        let selectedProductCheckboxesHTML = '';

        if (shopifyData.success) {
          let customSelection = false;

          $.each(Object.values(shopifyData.products).sort(), (i, item) => {
            if (item !== null) {
              const checkedProp = (item.selected) ? ' checked' : '';

              // If we have at least one selected product then that means we have a custom selection
              if (item.selected) {
                customSelection = true;
              }

              selectedProductCheckboxesHTML += `
                <div class="row align-items-center m-b-0_5">
                    <div class="col-3">
                        <img src="${item.default_image}" alt="" style="max-width: 75px" />
                    </div>
                    <div class="col">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" name="shopify_product[${item.id}]" id="shopify_product_${item.id}" value="${item.name.replace('"', '\\"')}" ${checkedProp}>
                            <label class="custom-control-label" for="shopify_product_${item.id}">${item.name}</label>
                        </div>
                    </div>
                </div>`;
            }
          });

          $selectProductsModalShopifyCustomSelection.prop('checked', customSelection).trigger('change');
        } else {
          selectedProductCheckboxesHTML += shopifyData.message;
        }

        $('#selectProductsModal .modal-body #selectShopifyProducts-checkboxes').html(selectedProductCheckboxesHTML);
      }),
      $.getJSON(`/campaign/edit/budget/woocommerce-products-for-scaling/get?project_id=${projectID}&campaign=${campaignID}`, (woocommerceData) => {
        let selectedProductCheckboxesHTML = '';

        if (woocommerceData.success) {
          let customSelection = false;

          $.each(Object.values(woocommerceData.products).sort(), (i, item) => {
            if (item !== null) {
              const checkedProp = (item.selected) ? ' checked' : '';

              // If we have at least one selected product then that means we have a custom selection
              if (item.selected) {
                customSelection = true;
              }

              selectedProductCheckboxesHTML += `
                <div class="row align-items-center m-b-0_5">
                    <div class="col-3">
                        <img src="${item.default_image}" alt="" style="max-width: 75px" />
                    </div>
                    <div class="col">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" name="woocommerce_product[${item.id}]" id="woocommerce_product_${item.id}" value="${item.name.replace('"', '\\"')}" ${checkedProp}>
                            <label class="custom-control-label" for="woocommerce_product_${item.id}">${item.name}</label>
                        </div>
                    </div>
                </div>`;
            }
          });

          $selectProductsModalWooCommerceCustomSelection.prop('checked', customSelection).trigger('change');
        } else {
          selectedProductCheckboxesHTML += woocommerceData.message;
        }

        $('#selectProductsModal .modal-body #selectWooCommerceProducts-checkboxes').html(selectedProductCheckboxesHTML);
      }),
    ).done(() => {
      $('.loader, #loading-overlay').hide();
      $selectProductsModal.appendTo('body').modal('show');
    });
  });

  $('.selectpicker-with-all').on('change', (e) => {
    handleSelectpickerWithAll(e.currentTarget);
  }).trigger('change');

  $selectProductsModalSubmitButton.on('click', (event) => {
    event.preventDefault();
    $selectProductsModalForm.submit();
  });

  $selectProductsModalAmazonCustomSelection.on('change', function selectAmazonProductsModalCustomSelectionChange() {
    if ($(this).is(':checked')) {
      $selectProductsModalAmazonCustomSelectionHolder.show();
    } else {
      $selectProductsModalAmazonCustomSelectionHolder.hide();
    }
  }).trigger('change');

  $selectProductsModalAmazonLimitToSpecificCountries.on('change', function selectProductsModalAmazonLimitToSpecificCountriesChange() {
    if ($(this).is(':checked')) {
      $selectProductsModalAmazonSpecificCountriesHolder.show();
    } else {
      $selectProductsModalAmazonSpecificCountriesHolder.hide();
    }
  }).trigger('change');

  $selectProductsModalAmazonSelectAll.on('change', function selectAmazonProductsModalSelectAllChange() {
    $('#selectAmazonProducts-checkboxes input[type="checkbox"]').prop('checked', $(this).is(':checked'));
  });

  $('#selectAmazonProducts-checkboxes input[type="checkbox"]').on('change', () => {
    $selectProductsModalAmazonSelectAll.prop('checked', false);
  });

  $selectProductsModalShopifyCustomSelection.on('change', function selectProductsModalShopifyCustomSelectionChange() {
    if ($(this).is(':checked')) {
      $selectProductsModalShopifyCustomSelectionHolder.show();
    } else {
      $selectProductsModalShopifyCustomSelectionHolder.hide();
    }
  }).trigger('change');

  $selectProductsModalShopifyLimitToSpecificCountries.on('change', function selectProductsModalShopifyLimitToSpecificCountriesChange() {
    if ($(this).is(':checked')) {
      $selectProductsModalShopifySpecificCountriesHolder.show();
    } else {
      $selectProductsModalShopifySpecificCountriesHolder.hide();
    }
  }).trigger('change');

  $selectProductsModalShopifySelectAll.on('change', function selectProductsModalShopifySelectAllChange() {
    $('#selectShopifyProducts-checkboxes input[type="checkbox"]').prop('checked', $(this).is(':checked'));
  });

  $('#selectShopifyProducts-checkboxes input[type="checkbox"]').on('change', () => {
    $selectProductsModalShopifySelectAll.prop('checked', false);
  });

  $selectProductsModalWooCommerceCustomSelection.on('change', function selectProductsModalWooCommerceCustomSelectionChange() {
    if ($(this).is(':checked')) {
      $selectProductsModalWooCommerceCustomSelectionHolder.show();
    } else {
      $selectProductsModalWooCommerceCustomSelectionHolder.hide();
    }
  }).trigger('change');

  $selectProductsModalWooCommerceLimitToSpecificCountries.on('change', function selectProductsModalWooCommerceLimitToSpecificCountriesChange() {
    if ($(this).is(':checked')) {
      $selectProductsModalWooCommerceSpecificCountriesHolder.show();
    } else {
      $selectProductsModalWooCommerceSpecificCountriesHolder.hide();
    }
  }).trigger('change');

  $selectProductsModalWooCommerceSelectAll.on('change', function selectProductsModalWooCommerceSelectAllChange() {
    $('#selectWooCommerceProducts-checkboxes input[type="checkbox"]').prop('checked', $(this).is(':checked'));
  });

  $('#selectWooCommerceProducts-checkboxes input[type="checkbox"]').on('change', () => {
    $selectProductsModalWooCommerceSelectAll.prop('checked', false);
  });

  /** EVENT TRIGGER HANDLERS -- END */
}

function initAutoTest() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  endDateAutoTest();
  setupAOForm($('#autoOptimizationForm-autoTest'), 'Auto Test', $('#autoOptimizationForm-autoTest-submit'), 'update-ao-settings-auto-test');
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  setupAOSectionHandler($('#autoTestEnabled'), $('#autoOptimizationForm-autoTest .card input:not(.btn)'));
  /** EVENT TRIGGER HANDLERS -- END */
}

function initColdAudiencesOverride() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  setupAOForm($('#autoOptimizationForm-coldAudienceOverride'), 'Cold Audience Override', $('#autoOptimizationForm-coldAudienceOverride-submit'), 'update-ao-settings-cold-audience-override');
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  setupAOSectionHandler($('#coldAudienceOverrideEnabled'), $('#autoOptimizationForm-coldAudienceOverride .card select'));
  /** EVENT TRIGGER HANDLERS -- END */
}

function initConversionSources() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  setupAOForm($('#autoOptimizationForm-conversionSources'), 'Conversions Source', $('#autoOptimizationForm-conversionSources-submit'), 'update-ao-settings-conversions-source');
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  $conversionSourcePercentageSlider.on('change', function conversionSourcePercentageFacebookConversionSourcePercentageChange() {
    const facebook = $(this).val();
    const google = 100 - facebook;

    $conversionSourcePercentageFacebook.html(`${facebook}%`);
    $conversionSourcePercentageGoogle.html(`${google}%`);
  }).trigger('change');
  /** EVENT TRIGGER HANDLERS -- END */
}

function initOverrideMaxWarmMultiplier() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  setupAOForm($('#autoOptimizationForm-overrideMaxWarmMultiplier'), 'Override Max Warm Multiplier', $('#autoOptimizationForm-overrideMaxWarmMultiplier-submit'), 'update-ao-settings-override-max-warm-multiplier');
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  setupAOSectionHandler($('#updateOptimizationSettings-overrideMaxWarmMultiplier'), $('#autoOptimizationForm-overrideMaxWarmMultiplier .card input:not(.btn)'));
  /** EVENT TRIGGER HANDLERS -- END */
}

function initBuildOptions() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  initBuildDatePickers();
  setupAOForm($autoOptFormBuilds, 'Build Settings', $autoOptFormBuildsButton, 'update-ao-settings-build-settings', true, undefined, (data) => {
    const { turnOnFbCampaignAlert } = data;

    if (turnOnFbCampaignAlert) {
      return turnOnFbCampaignAlert;
    }

    return '';
  });
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  $('.buildStartDate, .buildEndDate').on('change', () => {
    validateRunDates();
    validateOverruns();
  });
  $buildUtilizeForOverruns.on('change', () => {
    validateOverruns();
  });
  /** EVENT TRIGGER HANDLERS -- END */
}

function initPendingBudgets() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  $pendingBudgetChangesModalForm.validate({
    invalidHandler() {
      $(this).addClass('was-validated');
    },
    submitHandler(form) {
      $('.loader, #loading-overlay').show();
      $pendingBudgetChangesModalApproveButton.prop('disabled', true);
      $pendingBudgetChangesModalDenyButton.prop('disabled', true);

      $.ajax({
        url: `/campaign/edit/budget/process?project_id=${projectID}&campaign=${campaignID}&action=approve-pending-ao-settings`,
        type: 'post',
        data: $(form).serialize(),
        dataType: 'json',
      })
        .done((data) => {
          $('.loader, #loading-overlay').hide();

          if (data.success) {
            swal.fire({
              icon: 'success',
              title: 'Pending Campaign Budget Changes Approved Successfully',
              timer: 3000,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            }).then(() => {
              $('.loader, #loading-overlay').show();
              $pendingBudgetChangesModalApproveButton.prop('disabled', false);
              $pendingBudgetChangesModalDenyButton.prop('disabled', false);
              $pendingBudgetChangesModal.modal('hide');
              window.location.reload();
            });
          } else {
            triggerErrorSwal(data.message).then(() => {
              $pendingBudgetChangesModalApproveButton.prop('disabled', false);
              $pendingBudgetChangesModalDenyButton.prop('disabled', false);
            });
          }
        })
        .fail((jqXHR, status, error) => {
          triggerErrorSwal(error).then(() => {
            $pendingBudgetChangesModalApproveButton.prop('disabled', false);
            $pendingBudgetChangesModalDenyButton.prop('disabled', false);
          });
        });
    },
  });
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  $('button#viewPendingBudgetChanges').on('click', (event) => {
    event.preventDefault();
    $pendingBudgetChangesModalOverrideDailyBudgetEnd.daterangepicker({
      singleDatePicker: true,
      autoUpdateInput: false,
      minDate: $('#lifetimeBudgetStart').val(),
    },
    (start) => {
      const dateSelected = start.format('MM/DD/YYYY');
      $pendingBudgetChangesModalOverrideDailyBudgetEnd.val(dateSelected);
    });
    $pendingBudgetChangesModal.appendTo('body').modal('show');
  });

  $pendingBudgetChangesModalApproveButton.on('click', (event) => {
    event.preventDefault();
    $pendingBudgetChangesModalForm.submit();
  });

  $pendingBudgetChangesModalDenyButton.on('click', (event) => {
    event.preventDefault();
    $('.loader, #loading-overlay').show();
    $pendingBudgetChangesModalApproveButton.prop('disabled', true);
    $pendingBudgetChangesModalDenyButton.prop('disabled', true);

    $.ajax({
      url: `/campaign/edit/budget/process?project_id=${projectID}&campaign=${campaignID}&action=deny-pending-ao-settings`,
      type: 'post',
      dataType: 'json',
    })
      .done((data) => {
        $('.loader, #loading-overlay').hide();

        if (data.success) {
          swal.fire({
            icon: 'success',
            title: 'Pending Campaign Budget Changes Denied Successfully',
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(() => {
            $('.loader, #loading-overlay').show();
            $pendingBudgetChangesModalApproveButton.prop('disabled', false);
            $pendingBudgetChangesModalDenyButton.prop('disabled', false);
            $pendingBudgetChangesModal.modal('hide');
            window.location.reload();
          });
        } else {
          triggerErrorSwal(data.message).then(() => {
            $pendingBudgetChangesModalApproveButton.prop('disabled', false);
            $pendingBudgetChangesModalDenyButton.prop('disabled', false);
          });
        }
      })
      .fail((jqXHR, status, error) => {
        triggerErrorSwal(error).then(() => {
          $pendingBudgetChangesModalApproveButton.prop('disabled', false);
          $pendingBudgetChangesModalDenyButton.prop('disabled', false);
        });
      });
  });
  /** EVENT TRIGGER HANDLERS -- END */
}

export const init = () => {
  $(() => {
    $autoOptFormBuilds = $('#autoOptimizationForm-builds');
    $autoOptFormBuildsButton = $('#autoOptimizationForm-builds-submit');
    $autoOptimize = $('select#autoOptimize');
    $autoTestEnd = $('#autoTestEnd');
    $budgetScalingBasedOn = $('#autoOptimizationForm-scalingBudget select#budget_scaling_based_on');
    $budgetScalingEvent = $('#autoOptimizationForm-scalingBudget select#budget_scaling_event');
    $budgetScalingGoalType = $('#autoOptimizationForm-scalingBudget select#budget_scaling_goal_type');
    $buildEndDates = $('.buildStartDate');
    $buildStartDates = $('.buildEndDate');
    $buildUtilizeForOverruns = $('.utilizeForOverrun input');
    $conversionSourcePercentageFacebook = $('#autoOptimizationForm-conversionSources .conversionSourcePercentage_facebook');
    $conversionSourcePercentageGoogle = $('#autoOptimizationForm-conversionSources .conversionSourcePercentage_google');
    $conversionSourcePercentageSlider = $('#autoOptimizationForm-conversionSources #facebookConversionSourcePercentage');
    $pendingBudgetChangesModal = $('#pendingBudgetChangesModal');
    $pendingBudgetChangesModalForm = $('#pendingBudgetChanges-form');
    $pendingBudgetChangesModalOverrideDailyBudgetEnd = $('#pendingBudgetChanges-budgetOverrideEnd');
    $pendingBudgetChangesModalApproveButton = $('#pendingBudgetChangesModal #pendingBudgetChanges-approve');
    $pendingBudgetChangesModalDenyButton = $('#pendingBudgetChangesModal #pendingBudgetChanges-deny');
    $aoScalingBudget = $('#autoOptimizationForm-scalingBudget .scalingBudget input');
    $aoScalingBudgetEvent = $('#autoOptimizationForm-scalingBudget .scalingBudgetEvent');
    $aoScalingBudgetEventCC = $('#autoOptimizationForm-scalingBudget .scalingBudgetEventCC');
    $aoScalingBudgetGoalTypeCPP = $('#autoOptimizationForm-scalingBudget .scalingBudgetGoalTypeCPP');
    $aoScalingBudgetGoalTypeROAS = $('#autoOptimizationForm-scalingBudget .scalingBudgetGoalTypeROAS');
    $selectProducts = $('#selectProducts');
    $selectProductsModal = $('#selectProductsModal');
    $selectProductsModalForm = $('#selectProducts-form');
    $selectProductsModalSubmitButton = $('#selectProductsModal #selectProducts-submit');
    $selectProductsModalAmazonCustomSelection = $('#selectAmazonProducts-custom-selection');
    $selectProductsModalAmazonCustomSelectionHolder = $('#selectAmazonProducts-custom-selection-holder');
    $selectProductsModalAmazonLimitToSpecificCountries = $('#selectAmazonProducts-limit-to-specific-countries');
    $selectProductsModalAmazonSpecificCountriesHolder = $('#selectAmazonProducts-specific-countries').closest('.form-group');
    $selectProductsModalAmazonSelectAll = $('#selectAmazonProducts-select-all');
    $selectProductsModalShopifyCustomSelection = $('#selectShopifyProducts-custom-selection');
    $selectProductsModalShopifyCustomSelectionHolder = $('#selectShopifyProducts-custom-selection-holder');
    $selectProductsModalShopifyLimitToSpecificCountries = $('#selectShopifyProducts-limit-to-specific-countries');
    $selectProductsModalShopifySpecificCountriesHolder = $('#selectShopifyProducts-specific-countries').closest('.form-group');
    $selectProductsModalShopifySelectAll = $('#selectShopifyProducts-select-all');
    $selectProductsModalWooCommerceCustomSelection = $('#selectWooCommerceProducts-custom-selection');
    $selectProductsModalWooCommerceCustomSelectionHolder = $('#selectWooCommerceProducts-custom-selection-holder');
    $selectProductsModalWooCommerceLimitToSpecificCountries = $('#selectWooCommerceProducts-limit-to-specific-countries');
    $selectProductsModalWooCommerceSpecificCountriesHolder = $('#selectWooCommerceProducts-specific-countries').closest('.form-group');
    $selectProductsModalWooCommerceSelectAll = $('#selectWooCommerceProducts-select-all');

    initAutoOptimize();
    initTimePeriods();
    initScalingBudget();
    initScalingBudgetProducts();
    initAutoTest();
    initColdAudiencesOverride();
    initConversionSources();
    initOverrideMaxWarmMultiplier();
    initBuildOptions();
    initPendingBudgets();
  });
};

export default { init };
