import swal from 'sweetalert2';
import uuid from 'uuid';

class Utilities {
  /**
   * Return a URL parameter.
   *
   * @param {string} name
   * @returns {*}
   */
  static getUrlParameter(name) {
    const sanitizedName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[?&]${sanitizedName}=([^&#]*)`);
    const results = regex.exec(window.location.search);

    return (results === null) ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  /**
   *  Javascript's version of ucFirst.
   *
   * @param {string} word
   * @returns {string}
   */
  static ucFirst(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  /**
   *  Javascript's version of ucWords().
   *
   * @param {string} sentence
   * @returns {string}
   */
  static ucWords(sentence) {
    return (`${sentence}`)
      .replace(/^(.)|\s+(.)/g, ($1) => $1.toUpperCase());
  }

  /**
   *  Adds a random number to the end on name. Used for Jquery validate when a form has duplicate fields.
   *
   * @param {string} name
   * @returns {string}
   */
  static generateRandomName(name) {
    return `${name}_${uuid.v4()}`;
  }

  /**
   * Convert an integer to its numerical ordinal equivalent.
   *
   * @param {string} i
   * @return {string}
   */
  static intToNumericalOrdinal(i) {
    const abbreviation = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
    if (((i % 100) > 10) && ((i % 100) < 14)) {
      return `${i}th`;
    }
    return i + abbreviation[i % 10];
  }

  /**
   * flips a object
   *
   * @param {Object} object
   */
  static flipObject(object) {
    const out = {};
    Object.entries(object).forEach(([value, key]) => {
      out[key] = value;
    });
    return out;
  }

  /**
   * @param {string} buttonsSelector
   */
  static buttonsLoading(buttonsSelector = '.nextBtn, .prevBtn') {
    $(buttonsSelector).prop('disabled', true).addClass('btn-secondary').text('Loading...');
  }

  /**
   * @param {string} nextButtonSelector
   * @param {string} prevButtonSelector
   */
  static buttonsStopLoading(nextButtonSelector = '.nextBtn', prevButtonSelector = '.prevBtn') {
    $(nextButtonSelector).prop('disabled', false).removeClass('btn-secondary').html('Next <i class="fas fa-arrow-right"></i>');
    $(prevButtonSelector).prop('disabled', false).html('<i class="fas fa-arrow-left"></i> Previous');
  }

  /**
   * @param {string} buttonsSelector
   */
  static loadingStart(buttonsSelector = '.nextBtn, .prevBtn') {
    Utilities.buttonsLoading(buttonsSelector);
    $('.loader, #loading-overlay').show();
  }

  /**
   * @param {string} nextButtonSelector
   * @param {string} prevButtonSelector
   */
  static loadingStop(nextButtonSelector = '.nextBtn', prevButtonSelector = '.prevBtn') {
    Utilities.buttonsStopLoading(nextButtonSelector, prevButtonSelector);
    $('.loader, #loading-overlay').hide();
  }

  /**
   * Trigger error swal alert.
   *
   * @param errorMessage
   * @returns {Promise<SweetAlertResult<Awaited<unknown>>>}
   */
  static triggerErrorSwal(errorMessage) {
    return swal.fire({
      icon: 'error',
      title: errorMessage,
      timer: 3000,
      customClass: {
        confirmButton: 'btn btn-primary',
      },
    });
  }
}

export default Utilities;
